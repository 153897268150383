<template>
  <div class="agency-order-thankyou">
    <div class="ready-dive-in">
        <h2 class="text-center font-weight-bold">Ready To Dive In!</h2>
        <h4 class="mt-4 text-center">Your RingBot account is all set up (hooray!).</h4>
        <p class="mt-4 text-center">We've sent you an email to confirm your email address - click the link in that email to access your account.</p>
        <p class="mt-4 text-center">If you didn't get the email, or you need assistance, contact us at: <a href="mailto:support@ringbot.io?Subject=Free Trial Signup Question" target="_top" class="text-primary">support@ringbot.io </a> or text us at (858) 293-8500</p>
        <div class="mt-4 text-center">
        <router-link :to="{ name: 'login' }" class="btn btn-primary">Login</router-link>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },

  data () {
    return {
    }
  },

  mounted () {
    
  },

  methods: {
  }
}
</script>

<style lang="scss">
  .agency-order-thankyou {
    width: 840px;
    margin: 0 auto;
    background-color: rgb(242, 242, 242);
    padding: 50px 30px;
    .ready-dive-in {
      p {
        font-size: 18px;
      }
    }
  }
</style>
